<template>
  <div class="area_quick">
    <a href="javascript:void(0);" class="ico_account link_quick" @click.prevent="openToggle">
      퀵 메뉴
    </a>
    <div :class="[{ box_open: isOpen }, 'box_quick']">
      <!-- 오픈시 open 클래스 추가 -->
      <strong class="tit_txt">그룹웨어</strong>
      <ul class="list_quikmenu">
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <a href="javascript:void(0);" class="link_txt">-->
        <!--            <img src="http://via.placeholder.com/70x70" width="70" height="70" alt="" />-->
        <!--            <span class="txt_quick">일반결재</span>-->
        <!--          </a>-->
        <!--        </li>-->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommQuickMenu",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openToggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
